.custom-report__filter > .custom-report__filter-close {
    visibility: hidden;
}

.custom-report__filter:hover > .custom-report__filter-close {
    visibility: visible;
}

.custom-report__filter-property, .custom-report__filter-values {
    padding-left: 10px;
    padding-right: 10px;
}

.custom-report__filter-property--selected {

}
