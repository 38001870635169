.fc .fc-button-primary {
    background-color: #3e86ff;
    border-color: #3e86ff;
    outline: 0;
    text-transform: capitalize;
    opacity: 1;
}

.fc .fc-button-primary:hover {
    background-color: #5494ff;
    border-color: #5494ff;
}

.fc .fc-button-primary:disabled {
    background-color: #adb5bd;
    border-color: #adb5bd;
    opacity: 1;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #1660dc;
    border-color: #1660dc;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: white;
    border: solid 3px #5494ff;
}

.fc .fc-scrollgrid table {
    border-style: none;
}
